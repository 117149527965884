import React, { useState } from 'react';
import './RadioButtonGroup.css';

const RadioButtonGroup = ({ options, name, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className='radio-button-group'>
      {options.map((option, index) => (
        <label key={index} className='radio-label'>
          <input
            type='radio'
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            className='radio-input'
          />
          {option.label}
          <span className='radio-custom'></span>
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
