import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './FinancialReport.css';

const FinancialReport = () => {
  const [report, setReport] = useState(null);
  const [c1, setC1] = useState(getRandomColor());

  useEffect(() => {
    fetch('https://demostoreapi.bombastic.ps/api/getFinancialReport', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setReport(data.data))
      .catch((error) =>
        console.error('Error fetching financial report:', error)
      );
  }, []);

  if (!report) {
    return <div>جاري التحميل...</div>;
  }

  function getRandomColor() {
    const palette = [
      '#ffadad',
      '#ffd6a5',
      '#ffc6ff',
      '#bdb2ff',
      '#a0c4ff',
      '#9bf6ff',
      '#caffbf',
      '#fdffb6',
    ];
    const randomIndex = Math.floor(Math.random() * palette.length);
    return palette[randomIndex];
  }

  const {
    totalPaymentsReceived,
    totalExpenses,
    totalOutstandingAmounts,
    totalSupplierPayments,
    totalSupplierOrders,
    totalSales,
    totalQuantityOfItemsSold,
    salesByCategory,
    salesByCity,
    averageOrderValue,
    topSellingProducts,
    salesByMonth,
    salesByDayOfWeek,
    customerPurchaseFrequency,
    topCustomersByRevenue,
    salesByCategoryOverTime,
    discountImpactOnSales,
    totalProducts,
    totalCustomers,
    salesByCoupon,
    totalRemainingForSuppliers,
  } = report;

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const filteredSalesByCity = salesByCity.filter((item) => item.city);

  return (
    <div>
      <h1>تقرير مالي</h1>
      <div className='cards'>
        <div className='card'>
          <h2>إجمالي المدفوعات المستلمة</h2>
          <p>{totalPaymentsReceived.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي المصاريف</h2>
          <p>{totalExpenses.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي الديون المستحقة</h2>
          <p>{totalOutstandingAmounts.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي مدفوعات الموردين</h2>
          <p>{totalSupplierPayments.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي طلبات الموردين</h2>
          <p>{totalSupplierOrders.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي المبيعات</h2>
          <p>{totalSales.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي كمية المنتجات المباعة</h2>
          <p>{totalQuantityOfItemsSold.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>متوسط قيمة الطلب</h2>
          <p>{averageOrderValue.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي المنتجات</h2>
          <p>{totalProducts.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي الزبائن</h2>
          <p>{totalCustomers.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>إجمالي المبالغ المتبقية للموردين</h2>
          <p>{totalRemainingForSuppliers.toFixed(2)}</p>
        </div>
        <div className='card'>
          <h2>تكرار شراء العملاء</h2>
          <p>{customerPurchaseFrequency.toFixed(2)}</p>
        </div>
      </div>
      <div className='charts'>
        <div className='chart-container'>
          <h2>المبيعات حسب الفئة</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: salesByCategory.map((item) => item.category),
                datasets: [
                  {
                    label: 'المبيعات حسب الفئة',
                    data: salesByCategory.map((item) => item.totalSales),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>المبيعات حسب المدينة</h2>
          <div className='chart-wrapper'>
            <Pie
              data={{
                labels: filteredSalesByCity.map((item) => item.city),
                datasets: [
                  {
                    label: 'المبيعات حسب المدينة',
                    data: filteredSalesByCity.map((item) => item.totalSales),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>المبيعات الشهرية</h2>
          <div className='chart-wrapper'>
            <Line
              data={{
                labels: salesByMonth.map((item) => item.month),
                datasets: [
                  {
                    label: 'المبيعات الشهرية',
                    data: salesByMonth.map((item) => item.totalSales),
                    borderColor: c1,
                    backgroundColor: c1,
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>المبيعات حسب أيام الأسبوع</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: salesByDayOfWeek.map((item) => item.dayOfWeek),
                datasets: [
                  {
                    label: 'المبيعات حسب أيام الأسبوع',
                    data: salesByDayOfWeek.map((item) => item.totalSales),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>العملاء الأعلى إيرادات</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: topCustomersByRevenue.map((item) => item.name),
                datasets: [
                  {
                    label: 'إيرادات العملاء',
                    data: topCustomersByRevenue.map(
                      (item) => item.totalRevenue
                    ),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>المبيعات باستخدام الكوبون</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: salesByCoupon.map((item) => item.coupon),
                datasets: [
                  {
                    label: 'المبيعات باستخدام الكوبون',
                    data: salesByCoupon.map((item) => item.totalSales),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>أفضل المنتجات مبيعا</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: topSellingProducts.map((item) => item.name),
                datasets: [
                  {
                    label: 'أفضل المنتجات مبيعا',
                    data: topSellingProducts.map(
                      (item) => item.totalQuantitySold
                    ),
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>المبيعات حسب الفئة على مر الزمن</h2>
          <div className='chart-wrapper'>
            <Line
              data={{
                labels: [
                  ...new Set(salesByCategoryOverTime.map((item) => item.month)),
                ],
                datasets: salesByCategoryOverTime.reduce((acc, item) => {
                  const categoryIndex = acc.findIndex(
                    (dataset) => dataset.label === item.category
                  );
                  if (categoryIndex !== -1) {
                    acc[categoryIndex].data.push(item.totalSales);
                  } else {
                    const c = getRandomColor();
                    acc.push({
                      label: item.category,
                      data: [item.totalSales],
                      borderColor: c,
                      backgroundColor: c,
                    });
                  }
                  return acc;
                }, []),
              }}
              options={chartOptions}
            />
          </div>
        </div>

        <div className='chart-container'>
          <h2>تأثير الخصم على المبيعات</h2>
          <div className='chart-wrapper'>
            <Bar
              data={{
                labels: ['إجمالي الخصم', 'إجمالي المبيعات بعد الخصم'],
                datasets: [
                  {
                    label: 'تأثير الخصم على المبيعات',
                    data: [
                      discountImpactOnSales.totalDiscountAmount,
                      discountImpactOnSales.totalSalesAfterDiscount,
                    ],
                    backgroundColor: [
                      '#ffadad',
                      '#ffd6a5',
                      '#ffc6ff',
                      '#bdb2ff',
                      '#a0c4ff',
                      '#9bf6ff',
                      '#caffbf',
                      '#fdffb6',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialReport;
