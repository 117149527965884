import React, { useState, useEffect } from 'react';
import './InputChips.css';

const ChipInput = ({ property, setProperties, index }) => {
  const [chips, setChips] = useState(property.values);
  const [inputValue, setInputValue] = useState('');

  const handleAddChip = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      setChips([...chips, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveChip = (index) => {
    setChips(chips.filter((_, i) => i !== index));
  };
  const updateItemAtIndex = (index, newItem) => {
    setProperties((prevItems) => {
      return prevItems.map((item, i) =>
        i === index ? { ...item, ...newItem } : item
      );
    });
  };

  useEffect(() => {
    updateItemAtIndex(index, { values: chips });
  }, [chips]);

  return (
    <>
      <div className='chip-container'>
        {chips.map((chip, index) => (
          <div className='chip' key={index}>
            <label
              style={{
                fontWeight: 'bold',
              }}
            >
              {chip}
            </label>

            <button onClick={() => handleRemoveChip(index)}>x</button>
          </div>
        ))}
        <input
          className='chip-input'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleAddChip}
          placeholder='ادخل القيمة واضغط Enter'
        />
      </div>
    </>
  );
};

export default ChipInput;
