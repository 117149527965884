import React, { useState, useEffect } from 'react';

import Loading from './Loading';

import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Typography,
  InputAdornment,
  Autocomplete,
  TextField,
} from '@mui/material';

import { toast } from 'react-toastify';

import { addSupplier, getSuppliers } from '../APIS';

const AddSupplierDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleSave = async () => {
    if (!name || !mobile || !company || !country || !email) {
      // notifyError('الرجاء ادخال كافة البيانات');
      setErrorMessage('الرجاء ادخال كافة البيانات');
    } else {
      const supplier = {
        name,
        mobile,
        company,
        country,
        email,
      };

      setErrorMessage('');

      setLoading(true);

      const response = await addSupplier(supplier);

      if (response.status !== 'success') {
        toast.error('لم تتم عملية الاضافة بنجاح ، حاول مرة اخرى', 'top-left');
      } else {
        toast.success('تمت عملية اضافة المورد بنجاح ', 'top-left');
      }

      props.setOpenAddDialog(false);
      try {
        const SupplierData = await getSuppliers(); // Assuming getProducts returns product data
        props.setSuppliers(SupplierData.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Dialog
            open={props.openAddDialog}
            onClose={() => props.setOpenAddDialog(false)}
            PaperProps={{
              sx: { direction: 'rtl', width: '600px' },
            }}
          >
            <DialogContent>
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الاسم
              </InputLabel>
              <Input
                sx={{ margin: 2, width: '90%' }}
                placeholder='الاسم'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الهاتف
              </InputLabel>
              <Input
                sx={{ margin: 2, width: '90%' }}
                placeholder='الهاتف '
                type='text'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />

              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الشركة
              </InputLabel>
              <Input
                sx={{ margin: 2, width: '90%' }}
                placeholder='الشركة'
                type='text'
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />

              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الدولة
              </InputLabel>
              <Input
                sx={{ margin: 2, width: '90%' }}
                placeholder='الدولة'
                type='text'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />

              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الايميل
              </InputLabel>
              <Input
                sx={{ margin: 2, width: '90%' }}
                placeholder='الايميل '
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'right' }}>
              <Button onClick={handleSave}>إضافة</Button>

              <Button
                onClick={() => {
                  props.setOpenAddDialog(false);
                  setErrorMessage('');
                }}
              >
                إلغاء
              </Button>

              <InputLabel htmlFor='name' sx={{ color: 'red' }}>
                {errorMessage}
              </InputLabel>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default AddSupplierDialog;
