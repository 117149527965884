import React, { useState, useEffect } from 'react';

import {
  getSuppliersOrders,
  editSupplierOrder,
  getSuppliersOrderPayments,
  getSuppliersOrderAttachments,
} from '../APIS';
import Loading from './Loading';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import AddSupplierOrderDialog from './AddSupplierOrderDailog';
import SuppliersOrderPaymentsDialog from './SuppliersOrderPaymentsDialog';
import ShowFilesDialog from './ShowFilesDialog';

import AddIcon from '@mui/icons-material/Add';

import { toast } from 'react-toastify';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';

const useFakeMutation = () => {
  return React.useCallback(
    (supplierOrder) =>
      new Promise((resolve, reject) => {
        resolve({ ...supplierOrder });
      }),
    []
  );
};

const Expenses = () => {
  const [suppliersOrders, setSuppliersOrders] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openShowFiles, setOpenShowFiles] = useState(false);
  const [openPaymentsDialog, setOpenPaymentsDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIdSupplier, setSelectedIdSupplier] = useState(0);

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'name',
      headerName: 'الاسم',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'text',
    },

    {
      field: 'mobile',
      headerName: 'الهاتف',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      type: 'text',
      editable: false,
    },

    {
      field: 'company',
      headerName: 'الشركة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'text',
    },
    {
      field: 'country',
      headerName: 'الدولة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'text',
    },
    {
      field: 'email',
      headerName: 'الايميل',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 200,
      editable: false,
      type: 'text',
    },
    {
      field: 'amount',
      headerName: 'المبلغ',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'totalPaid',
      headerName: 'المدفوع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },
    {
      field: 'totalRemaining',
      headerName: 'المتبقي',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
    },

    {
      field: 'dateadded',
      headerName: 'تاريخ الاضافة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 150,
      editable: false,
      type: 'Date',
      valueGetter: (value, row) => {
        return formatDate(row.dateadded);
      },
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<AttachMoneyIcon sx={{ color: 'black' }} />}
            label='payments'
            onClick={() => {
              handleGetSuppliersOrderPayments(id);
              setSelectedIdSupplier(id);
              setOpenPaymentsDialog(true);
            }}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DescriptionIcon sx={{ color: 'black' }} />}
            label='Attachments'
            onClick={() => {
              // handleGetAttachment(id);
              setSelectedIdSupplier(id);
              setOpenShowFiles(true);
            }}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const data = {
        amount: newRow.amount,
        id: newRow.orderId,
      };

      const result = await editSupplierOrder(data);
      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleGetSuppliersOrderPayments = async (id) => {
    try {
      setLoading(true);
      const response = await getSuppliersOrderPayments(id);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const handleGetAttachment = async (id) => {
    try {
      setLoading(true);
      const response = await getSuppliersOrderAttachments(id);

      window.open(
        'https://view.officeapps.live.com/op/view.aspx?src=' +
          response.data[0].url,
        '_blank',
        'noopener,noreferrer'
      );

      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchSuppliersOrders = async () => {
      try {
        setLoading(true);
        const response = await getSuppliersOrders();

        const modifiedData = response.data.map((item) => ({
          ...item,
          id: item.orderId,
          orderId: undefined,
        }));

        setSuppliersOrders(modifiedData);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchSuppliersOrders();
  }, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddDialog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              //   setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={suppliersOrders}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/* Dialog for adding Supplier*/}
      {openAddDialog && (
        <AddSupplierOrderDialog
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setSuppliersOrders={setSuppliersOrders}
        />
      )}

      {/*Dialog for adding Supplier*/}
      {openPaymentsDialog && (
        <SuppliersOrderPaymentsDialog
          orderId={selectedIdSupplier}
          openPaymentsDialog={openPaymentsDialog}
          setOpenPaymentsDialog={setOpenPaymentsDialog}
        />
      )}

      {/* Dialog for show files*/}
      {openShowFiles && (
        <ShowFilesDialog
          id={selectedIdSupplier}
          openShowFiles={openShowFiles}
          setOpenShowFiles={setOpenShowFiles}
        />
      )}

      {}
    </>
  );
};

export default Expenses;
