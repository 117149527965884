import React, { useState, useEffect } from 'react';
import Loading from './Loading';

import EditIcon from '@mui/icons-material/Edit';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMediaQuery } from '@mui/material';

import { deleteFromCart, editCart, returnCart, getOrderDetails } from '../APIS';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

const useFakeMutation = () => {
  return React.useCallback(
    (product) =>
      new Promise((resolve, reject) => {
        resolve({ ...product });
      }),
    []
  );
};

const Cart_Table_NonEditable = (props) => {
  const [products, setProducts] = useState(props.cart);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [typeOfTextField, setTypeOfTextField] = useState('number');

  const mutateRow = useFakeMutation();

  useEffect(() => {
    setProducts(props.cart);
  }, [props.cart]);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: 'productItemNumber',
      headerName: 'رقم المنتج',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      editable: false,
    },

    {
      field: 'image',
      headerName: 'الصورة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => (
        <Box sx={{ padding: 1 }}>
          <img
            src={params.value}
            alt='item'
            style={{
              width: '50%',
              aspectRatio: '1/1',
              objectFit: 'contain',
              cursor: 'pointer',
            }}
            // onClick={() => handleImageClick(params.value)}
            // onDoubleClick={() => handleEditImage(params.value)}
          />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'الاسم',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'options',
      headerName: 'خصائص',
      align: 'center',
      flex: 1,
      minWidth: 170,
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {params.value.map((option, index) => (
            <div key={index}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ marginLeft: '5px' }}>{option.label} :</p>
                <p>{option.value}</p>
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'quantity',
      headerName: 'الكمية',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'number',
      renderCell: (params) => {
        return params.value === 0 ? (
          <p style={{ color: 'red' }}>مرتجع</p>
        ) : (
          params.value
        );
      },
    },
    {
      field: 'price',
      headerName: 'السعر',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
    },
    {
      field: 'total',
      headerName: 'المجموع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: (row) => {
        return [
          row.row.quantity != 0 ? (
            <Box sx={{ width: '70px', padding: '12px' }}>
              <Button
                sx={{
                  color: 'white',
                  backgroundColor: '#e60000bb',
                  '&:hover': {
                    backgroundColor: '#e60000', // Change the color on hover
                  },
                }}
                onClick={() => {
                  handleReturnButtonClick();
                  setSelectedId(row.id);
                }}
              >
                مرتجع
              </Button>
            </Box>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editCart(newRow);

      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else if (result.status == 'success') {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        props.fetchCart(localStorage.getItem('randomID'));
        return response;
      }
    },
    [mutateRow]
  );

  const handleDeleteFromCart = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFromCart(id);
      props.fetchCart(localStorage.getItem('randomID'));

      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  // const handleImageClick = (imageUrl) => {
  //   setSelectedImage(imageUrl);
  //   setOpenImageModal(true);
  // };

  const handleConfirmDelete = async ({}) => {
    // setOpenDeleteConfirmation(false);
    // setLoading(true);
    // // const response = await deleteProduct(selectedItemId);
    // // const productsData = await getProducts();
    // if (response.msg !== 'product deleted') {
    //   toast.error('لم تتم عملية الحذف بنجاح', 'top-left');
    // } else if (response.msg == 'product deleted') {
    //   toast.success('تمت عملية الحذف بنجاح ', 'top-left');
    // }
    // setProducts(productsData.data);
    // setLoading(false);
  };

  // const handleDeleteClick = (id) => () => {
  //   setSelectedItemId(id);
  //   setOpenDeleteConfirmation(true);
  // };

  const handleReturnCart = async (id) => {
    try {
      setLoading(true);
      const response = await returnCart(id);

      const orderDetails = await getOrderDetails(props.orderNumber);
      setProducts(orderDetails.cart);
      setOpenDeleteConfirmation(false);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleReturnButtonClick = () => {
    setOpenDeleteConfirmation(true);
  };

  return (
    <>
      {' '}
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5, paddingTop: 1 }}>
        <Box sx={{ width: '100%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              setSelectedRow(row.row);
            }}
            sx={{
              height: 400,
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={products}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[10, 25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>
      {/*Dialog Component*/}
      <Dialog
        sx={{ textAlign: 'right' }}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'ارجاع منتج'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            هل أنت متأكد من عملية الارجاع ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color='primary'
          >
            الغاء
          </Button>
          <Button
            onClick={() => handleReturnCart(selectedId)}
            color='primary'
            autoFocus
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cart_Table_NonEditable;
