import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Loading from './Loading';
import AddCouponDialog from './AddCouponDialog';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getCoupons, editCoupon, deleteCoupon } from '../APIS';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

const useFakeMutation = () => {
  return React.useCallback(
    (coupon) =>
      new Promise((resolve, reject) => {
        resolve({ ...coupon });
      }),
    []
  );
};

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddCouponDialog, setOpenAddCouponDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const mutateRow = useFakeMutation();

  const typeOfCoupons = ['ثابت', 'نسبة مئوية'];

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'code',
      headerName: 'الكود',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },
    {
      field: 'coupontype',
      headerName: 'نوع الكوبون',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      type: 'text',
      type: 'singleSelect',
      minWidth: 150,
      valueOptions: typeOfCoupons,
      editable: true,
    },
    {
      field: 'couponvalue',
      headerName: 'قيمة الكوبون',
      align: 'center',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      editable: true,
    },
    {
      field: 'exp_date',
      headerName: 'تاريخ الانتهاء',
      align: 'center',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      type: 'text',
      align: 'center',
      editable: true,
      valueGetter: (value, row) => {
        return formatDate(row.exp_date);
      },
    },
    {
      field: 'validity',
      headerName: 'الصلاحية',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: 'red' }} />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editCoupon(newRow);

      if (result.msg.code == 'ER_DUP_ENTRY') {
        toast.error('هذا الكود مستخدم مسبقا', 'top-left');
      } else if (result.status == 'success') {
        toast.success('تم التعديل بنجاح', 'top-left');
        const result = await mutateRow(newRow);
        return result;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleDeleteClick = (id) => () => {
    setSelectedItemId(id);
    setOpenDeleteConfirmation(true);
  };

  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  }

  const handleConfirmDelete = async ({}) => {
    setOpenDeleteConfirmation(false);
    setLoading(true);
    const response = await deleteCoupon(selectedItemId);
    const couponsData = await getCoupons();

    if (response.status !== 'success') {
      toast.error('لم تتم عملية الحذف بنجاح', 'top-left');
    } else {
      toast.success('تمت عملية الحذف بنجاح ', 'top-left');
    }
    setCoupons(couponsData.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchCoupons = async () => {
      try {
        const couponsData = await getCoupons();
        setCoupons(couponsData.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchCoupons();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddCouponDialog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          {
            <DataGrid
              sx={{
                height: '700px',
                padding: '5px',
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              getRowHeight={() => 'auto'}
              rows={coupons}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableExporting
              disableRowSelectionOnClick
              disableColumnMenu
              pageSizeOptions={[10, 25, 50, 100]}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: {
                    disableToolbarButton: true,
                  },
                },
              }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          }
        </Box>
      </Box>

      <Dialog
        sx={{ textAlign: 'right' }}
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'حذف الكوبون'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            هل أنت متأكد من عملية الحذف ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirmation(false)}
            color='primary'
          >
            الغاء
          </Button>
          <Button onClick={handleConfirmDelete} color='primary' autoFocus>
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>

      {/*Dialog for adding products*/}
      {openAddCouponDialog && (
        <AddCouponDialog
          openAddCouponDialog={openAddCouponDialog}
          setOpenAddCouponDialog={setOpenAddCouponDialog}
          typeOfCoupons={typeOfCoupons}
          setCoupons={setCoupons}
        />
      )}
    </>
  );
};

export default Coupons;
