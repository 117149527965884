import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  DialogContent,
  DialogActions,
  Input,
  Button,
  Dialog,
  Box,
  InputLabel,
  Autocomplete,
  TextField,
} from '@mui/material';

import { addCoupon, getCoupons } from '../APIS';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from './Loading';

const AddCouponDialog = (props) => {
  const [code, setCode] = useState('');
  const [coupontype, setCoupontype] = useState('');
  const [couponvalue, setCouponvalue] = useState(0);
  const [exp_date, setExp_date] = useState('');
  const [validity, setValidity] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState('');

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleSave = async () => {
    if (!code || !coupontype || !couponvalue || !exp_date || !validity) {
      setErrorMessage('الرجاء ادخال كافة البيانات');
    } else {
      const coupon = {
        code,
        coupontype,
        couponvalue,
        exp_date,
        validity,
      };

      setErrorMessage('');

      setLoading(true);

      try {
        const response = await addCoupon(coupon);

        if (response.status !== 'success') {
          toast.error('لم تتم عملية الاضافة بنجاح', 'top-left');
        } else if (response.msg.code == 'ER_DUP_ENTRY') {
          toast.error('هذا الكود مستخدم مسبقا', 'top-left');
        } else {
          props.setOpenAddCouponDialog(false);
          const couponsData = await getCoupons();
          toast.success('تمت الاضافة بنجاح', 'top-left');
          props.setCoupons(couponsData.data);
        }
        setLoading(false);
      } catch (error) {
        throw error;
        setLoading(false);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dialog
          sx={{}}
          open={true}
          onClose={() => props.setOpenAddCouponDialog(false)}
          PaperProps={{
            sx: {
              direction: 'rtl',
              minWidth: '900px',
            },
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                marginRight: 2,
                border: '1px solid #ccc',
                overflow: 'hidden',
                padding: 1,
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الكود
              </InputLabel>
              <Input
                sx={{ margin: 1 }}
                autoFocus
                fullWidth
                placeholder='الكود'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                marginRight: 2,
                border: '1px solid #ccc',
                overflow: 'hidden',
                padding: 1,
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                نوع الكوبون
              </InputLabel>

              <Autocomplete
                sx={{
                  width: '30%',
                  margin: 2,
                  minWidth: isMobile ? 150 : 170,
                  textAlign: 'right',
                }}
                options={props.typeOfCoupons}
                onChange={(e, value) => {
                  setCoupontype(value);
                }}
                getOptionLabel={(coupon) => coupon}
                renderInput={(params) => (
                  <TextField {...params} sx={{ float: 'right' }} />
                )}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                marginRight: 2,
                border: '1px solid #ccc',
                overflow: 'hidden',
                padding: 1,
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 90,
                  marginLeft: 1,
                  overflow: 'hidden',
                }}
                marginDense
                htmlFor='name'
              >
                قيمة الكوبون
              </InputLabel>
              <Input
                sx={{ margin: 1 }}
                autoFocus
                fullWidth
                type='number'
                placeholder='قيمة الكوبون'
                value={couponvalue}
                onChange={(e) => setCouponvalue(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                marginRight: 2,
                border: '1px solid #ccc',
                overflow: 'hidden',
                padding: 1,
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                  marginLeft: 5,
                }}
                marginDense
                htmlFor='name'
              >
                تاريخ الانتهاء{' '}
              </InputLabel>

              <Box sx={{ zIndex: 9999 }}>
                <Calendar
                  value={exp_date}
                  onChange={(e) => setExp_date(e.value)}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'right',
                marginRight: 2,
                border: '1px solid #ccc',
                overflow: 'hidden',
                padding: 1,
                borderRadius: 5,
                margin: 1,
              }}
            >
              <InputLabel
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 70,
                }}
                marginDense
                htmlFor='name'
              >
                الصلاحية
              </InputLabel>
              <Input
                sx={{ margin: 1 }}
                autoFocus
                fullWidth
                type='number'
                placeholder='الصلاحية'
                value={validity}
                onChange={(e) => setValidity(e.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={handleSave}>إضافة</Button>

            <Button
              onClick={() => {
                props.setOpenAddCouponDialog(false);
                setErrorMessage('');
              }}
            >
              إلغاء
            </Button>

            <InputLabel htmlFor='name' sx={{ color: 'red' }}>
              {errorMessage}
            </InputLabel>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddCouponDialog;
