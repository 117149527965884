import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogIn from './screens/LogIn';
import Home from './screens/Home';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassowrd';
import Products from './components/Products';
import Customers from './components/Customers';
import Coupons from './components/Coupons';
import Cities from './components/Cities';
import AddOrders from './components/AddOrders';
import Structure from './components/Structure';
import Main from './components/Main';
import 'react-toastify/dist/ReactToastify.css';
import Orders from './components/Order';
import Suppliers from './components/Suppliers';
import Purchases from './components/Purchases';
import Expenses from './components/Expenses';
import Banners from './components/Banners';

function App() {
  const [redirected, setRedirected] = useState(false);

  return (
    <BrowserRouter>
      <div style={styles.app}>
        <Routes>
          <Route path='/' element={<LogIn />} />
          <Route
            path='/home'
            element={
              <Structure>
                <Main />
              </Structure>
            }
          />
          <Route path='/ResetPassword' element={<ResetPassword />} />
          <Route
            path='/ChangePassword'
            element={
              <Structure>
                <ChangePassword />
              </Structure>
            }
          />
          <Route
            path='/products'
            element={
              <Structure>
                <Products showAddToCart={false} />
              </Structure>
            }
          />

          <Route
            path='/orders'
            element={
              <Structure>
                <Orders />
              </Structure>
            }
          />

          <Route
            path='/addorder'
            element={
              <Structure>
                <AddOrders />
              </Structure>
            }
          />

          <Route
            path='/cities'
            element={
              <Structure>
                <Cities />
              </Structure>
            }
          />

          <Route
            path='/coupons'
            element={
              <Structure>
                <Coupons />
              </Structure>
            }
          />

          <Route
            path='/customers'
            element={
              <Structure>
                <Customers showAccountStatement={true} />
              </Structure>
            }
          />

          <Route
            path='/suppliers'
            element={
              <Structure>
                <Suppliers />
              </Structure>
            }
          />

          <Route
            path='/expenses'
            element={
              <Structure>
                <Expenses />
              </Structure>
            }
          />

          <Route
            path='/purchases'
            element={
              <Structure>
                <Purchases />
              </Structure>
            }
          />

          <Route
            path='/banners'
            element={
              <Structure>
                <Banners />
              </Structure>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

const styles = {
  app: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100vw',
    // height: '100vh',
    direction: 'rtl',
  },
};

// const styles = {
//   app: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     // width: '100vw',
//     // height: '100vh',
//     direction: 'rtl',
//   },
// };

export default App;
