import React, { useState, useEffect } from 'react';

import { getExpenses, addExpense, deleteExpense, editExpense } from '../APIS';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import Loading from './Loading';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Input,
} from '@mui/material';

import { useMediaQuery } from '@mui/material';

import AddSupplierOrderDialog from './AddSupplierOrderDailog';
import SuppliersOrderPaymentsDialog from './SuppliersOrderPaymentsDialog';
import ShowFilesDialog from './ShowFilesDialog';

import AddIcon from '@mui/icons-material/Add';

import { toast } from 'react-toastify';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import { Typography } from 'antd';

const useFakeMutation = () => {
  return React.useCallback(
    (supplierOrder) =>
      new Promise((resolve, reject) => {
        resolve({ ...supplierOrder });
      }),
    []
  );
};

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [reason, setReason] = useState('');
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'amount',
      headerName: 'المبلغ',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'number',
    },
    {
      field: 'reason',
      headerName: 'المقابل',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: true,
      type: 'text',
    },
    {
      field: 'dateadded',
      headerName: 'تاريخ الاضافة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'Date',
      valueGetter: (value, row) => {
        return formatDate(row.dateadded);
      },
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: 'red' }} />}
            label='Delete'
            onClick={() => handleDeleteExpenseButton(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const handleAddExpenseButton = async () => {
    if (!amount || !reason) {
      toast.error('الرجاء ادخال كامل البيانات');
    } else {
      const object = {
        amount: amount,
        reason: reason,
      };
      try {
        setLoading(true);
        const response = await addExpense(object);
        if (response.status != 'success') {
          toast.error('لم تتم عملية الاضافة بنجاح');
        } else {
          toast.success('تمت عملية الاضافة بنجاح');

          const new_expences = await getExpenses();
          setExpenses(new_expences.data);
        }
        setLoading(false);
      } catch (error) {
        throw error;
      }
    }
  };

  const handleDeleteExpenseButton = async (id) => {
    try {
      setLoading(true);

      const response = await deleteExpense(id);

      if (response.status != 'success') {
        toast.error('لم تتم عملية الحذف بنجاح');
      } else {
        toast.success('تمت عملية الحذف بنجاح');
        const new_expences = await getExpenses();
        setExpenses(new_expences.data);
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const data = {
        id: newRow.id,
        amount: newRow.amount,
        reason: newRow.reason,
      };

      const result = await editExpense(data);
      if (result.status !== 'success') {
        toast.error('لم تتم عملية التعديل بنجاح حاوب مرة اخرى', 'top-left');
      } else {
        toast.success('تم التعديل بنجاح', 'top-left');
        const response = await mutateRow(newRow);
        return response;
      }
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        setLoading(true);
        const response = await getExpenses();
        setExpenses(response.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchExpenses();
  }, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Box
          sx={{
            display: 'flex',
            width: 'fit-content',
            flexDirection: isMobile ? 'column' : 'row',
            border: '1px solid #ccc',
            borderRadius: '5px',
            margin: '5px',
            // minWidth: '700px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'right',
              marginRight: 2,
              overflow: 'hidden',
              padding: 1,
              borderRadius: 5,
              margin: 1,
            }}
          >
            <InputLabel
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                minWidth: 70,
              }}
              marginDense
              htmlFor='name'
            >
              المقابل
            </InputLabel>
            <Input
              sx={{ margin: 1 }}
              autoFocus
              fullWidth
              multiline
              placeholder='المقابل'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'right',
              marginRight: 2,
              overflow: 'hidden',
              padding: 1,
              borderRadius: 5,
              margin: 1,
            }}
          >
            <InputLabel
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                minWidth: 70,
              }}
              marginDense
              htmlFor='name'
            >
              المبلغ
            </InputLabel>
            <Input
              sx={{ margin: 1 }}
              autoFocus
              fullWidth
              type='number'
              placeholder='المبلغ'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>

          <Button
            color='primary'
            onClick={() => {
              handleAddExpenseButton();
            }}
          >
            إضافة
          </Button>
        </Box>

        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              //   setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={expenses}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            // disableColumnSorting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>

      {/* Dialog for adding Supplier*/}
      {/* {openAddDialog && (
        <AddSupplierOrderDialog
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setSuppliersOrders={setSuppliersOrders}
        />
      )} */}

      {/*Dialog for adding Supplier*/}
      {/* {openPaymentsDialog && (
        <SuppliersOrderPaymentsDialog
          id={selectedIdSupplier}
          openPaymentsDialog={openPaymentsDialog}
          setOpenPaymentsDialog={setOpenPaymentsDialog}
        />
      )} */}

      {/* Dialog for show files*/}
      {/* {openShowFiles && (
        <ShowFilesDialog
          id={selectedIdSupplier}
          openShowFiles={openShowFiles}
          setOpenShowFiles={setOpenShowFiles}
        />
      )} */}

      {}
    </>
  );
};

export default Expenses;
