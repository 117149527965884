import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import Logo from '../components/Logo';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import toaster ,{ToastContainer} from 'toastify-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logInAdmin } from '../APIS';
import { resetPassword } from '../APIS';
import Loading from '../components/Loading';

import { useNavigate } from 'react-router-dom';

const LogIn = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const paragraphStyle = isHovered
    ? { ...styles.paragraphStyle, ...styles.paragraphHover }
    : styles.paragraphStyle;

  const notifySuccess = (message) => {
    toast.success(message, 'top-right');
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await logInAdmin(email, password);

      setLoading(false);

      setMessage(result.msg);

      if (result.status != 'success') {
        console.error(result);
        setError(true);
      }

      if (result.status === 'success') {
        setSuccess(true);
        localStorage.setItem('token', result.token);
        navigate('/home');
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleResetPassword(event) {
    setLoading(true);
    event.preventDefault();

    try {
      const result = await resetPassword(email);

      setLoading(false);
      navigate(`/${result.link}`);
    } catch (error) {
      throw error;
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  async function handleKeyDown(event) {
    // Check if the key pressed is the Enter key (key code 13)
    if (event.keyCode === 13) {
      event.preventDefault();
      setLoading(true);
      try {
        const result = await logInAdmin(email, password);

        setLoading(false);

        setMessage(result.msg);

        if (result.status != 'success') {
          console.error(result);
          setError(true);
        }

        if (result.status === 'success') {
          setSuccess(true);
          localStorage.setItem('token', result.token);
          navigate('/home');
        }
      } catch (error) {
        throw error;
      }
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (success) {
      notifySuccess(message);
      setSuccess(false);
    }

    if (error) {
      notifyError(message);
      setError(false);
    }
  }, [success, error]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.href = '/home';
    }
  }, [window.location.href]);

  return (
    <>
      {loading ? <Loading /> : null}
      <div style={styles.logInContainer}>
        <div style={styles.header}>
          <h1>تسجيل الدخول</h1>
        </div>

        <div style={styles.logoContainer}>
          <Logo
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '90%',
            }}
          />
        </div>

        <div style={styles.inputsContainer}>
          <Input
            style={styles.inputStyle}
            placeholder='الايميل'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Input.Password
            style={styles.inputStyle}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {/* <p
            style={paragraphStyle}
            onClick={handleResetPassword}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            هل نسيت كلمة المرور ؟
          </p> */}
        </div>

        <Button
          type='primary'
          style={styles.buttonStyle}
          onClick={handleSubmit}
        >
          الدخول
        </Button>
        <ToastContainer />
      </div>
    </>
  );
};

const styles = {
  logInContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    // border: '2px solid #84848484',
    borderRadius: '10px',
    padding: '5px',
    width: '80%',
    height: '100%',
    boxShadow: '0px 0px 40px 0px rgba(0,0,0,0.2)',
    marginTop: 70,
  },

  logoContainer: {
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  inputsContainer: {
    // marginTop: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '300px',
  },

  inputStyle: {
    margin: '5px',
    width: '100%',
  },

  buttonStyle: {
    marginTop: '20px',
    backgroundColor: '#3C7BBE',
    fontFamily: 'DG_Ghayaty',
    color: '#fff',
    marginBottom: '30px',
  },

  paragraphStyle: {
    color: 'black',
    margin: '30px',
    cursor: 'pointer',
  },
  paragraphHover: {
    color: 'red',
  },
};

export default LogIn;
