import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getCities, editCities } from '../APIS';

const useFakeMutation = () => {
  return React.useCallback(
    (city) =>
      new Promise((resolve, reject) => {
        resolve({ ...city });
      }),
    []
  );
};

const Cities = () => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'name',
      headerName: 'الاسم',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      editable: false,
    },
    {
      field: 'price',
      headerName: ' سعر التوصيل',
      align: 'center',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      type: 'number',
      editable: true,
    },
  ];

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const result = await editCities(newRow);
      const response = await mutateRow(newRow);
      return response;
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCities = async () => {
      try {
        const cities = await getCities();
        setCities(cities.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchCities();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Box sx={{ width: '100%', direction: 'rtl' }}>
          <DataGrid
            sx={{
              height: '700px',
              padding: '5px',

              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => '50'}
            rows={cities}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Box>
      </Box>
    </>
  );
};

export default Cities;
