import React, { useState, useEffect } from 'react';
import DropZone from './DropZone';
import RadioButtonGroup from './RadioButtonGroup/RadioButtonGroup';
import AddBannerImageDialog from './AddBannerImageDialog';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Input,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import Loading from './Loading';

import { addBanner, getBanners, deleteBanner } from '../APIS';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';

import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { Typography } from 'antd';

const useFakeMutation = () => {
  return React.useCallback(
    (product) =>
      new Promise((resolve, reject) => {
        if (product.itemno?.trim() === '') {
          reject(new Error('Error while saving user: itemno cannot be empty.'));
        } else {
          resolve({ ...product, total: product.cost * product.quantity });
        }
      }),
    []
  );
};

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [image, setImage] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAddBannerDialog, setOpenAddBannerDailog] = useState(false);
  const mutateRow = useFakeMutation();

  const columns = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'url',
      headerName: 'الصورة',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => (
        <Box sx={{ padding: 1 }}>
          <img
            src={params.value}
            alt='item'
            style={{
              width: '100%',
              // aspectRatio: '1/1',
              objectFit: 'cover',
              cursor: 'pointer',
            }}
          />
        </Box>
      ),
    },
    {
      field: 'position',
      headerName: 'الموقع',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      editable: false,
      type: 'text',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: 'red' }} />}
            label='Delete'
            onClick={() => handleDeleteClick(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const fetchBanners = async () => {
    try {
      setLoading(true);
      const response = await getBanners();

      setLoading(false);

      if (response.status == 'success') {
        setBanners(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      setLoading(true);
      const response = await deleteBanner(id);
      setLoading(false);

      if (response.status != 'success') {
        toast.error('لم يتم حذف الاعلان بنجاح ، حاول مرة أخرى');
      } else {
        toast.success('تمت عملية الحذف بنجاح');
        fetchBanners();
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <Box sx={{ padding: 5 }}>
        <Button onClick={() => setOpenAddBannerDailog(true)}>
          <AddIcon />
        </Button>
        <Box sx={{ width: '98%', direction: 'rtl' }}>
          <DataGrid
            onRowClick={(row) => {
              setSelectedRow(row.row);
            }}
            sx={{
              height: '700px',
              padding: '5px',
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
            getRowHeight={() => 'auto'}
            rows={banners}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExporting
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[25, 50, 100]}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  disableToolbarButton: true,
                },
              },
            }}
          />
        </Box>
      </Box>

      {openAddBannerDialog && (
        <AddBannerImageDialog
          openAddBannerDialog={openAddBannerDialog}
          setOpenAddBannerDailog={setOpenAddBannerDailog}
          fetchBanners={fetchBanners}
        />
      )}
    </>
  );
};

export default Banners;
