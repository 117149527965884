import * as React from 'react';
import { Box, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PeopleIcon from '@mui/icons-material/People';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeIcon from '@mui/icons-material/Home';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AddIcon from '@mui/icons-material/Add';
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import { useNavigate } from 'react-router-dom';

import Logo from './Logo';
import supplier from '../assets/images/supplier.png';
import purchase from '../assets/images/purchase.png';
import product from '../assets/images/product.png';
import coupon from '../assets/images/coupon.png';
import order from '../assets/images/order.png';
import cityscape from '../assets/images/cityscape.png';
import people from '../assets/images/people.png';
import expenses from '../assets/images/expenses.png';
import scanner from '../assets/images/scanner.png';
import home from '../assets/images/home.png';
import password from '../assets/images/password.png';
import banner from '../assets/images/banner.png';

export default function NestedList({ toggleSidebar }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [selectedButton, setSelectedButton] = React.useState(null);

  const openPage = (page) => {
    setSelectedButton(page);
    if (page === 'login') {
      navigate('/');
      localStorage.removeItem('token');
    } else {
      navigate(`/${page}`);
    }
  };

  const getButtonStyle = (page) => ({
    backgroundColor: selectedButton === page ? '#C5C5C588' : 'transparent',
    // color: selectedButton === page ? 'white' : 'black',
  });

  return (
    <Box
      sx={{
        textAlign: 'right',
        flex: 1,
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 1,
      }}
    >
      <List
        sx={{ bgcolor: 'background.paper' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            {/* {isSmallScreen && ( */}
            <Button onClick={toggleSidebar} sx={{ color: 'gray' }}>
              <ArrowForwardIosOutlinedIcon
                sx={{
                  marginRight: 'auto',
                  marginLeft: '20px',
                  marginTop: '20px',
                }}
              />
            </Button>
            {/* )} */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Logo
                style={{
                  width: '200px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                customizedStyle={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
          </Box>
        }
      >
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('home'),
          }}
          onClick={() => {
            openPage('home');

            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            <img
              src={home}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='الرئيسية' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            borderTop: '1px solid gray',
            textAlign: 'right',
            ...getButtonStyle('customers'),
          }}
          onClick={() => {
            openPage('customers');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <PeopleIcon /> */}
            <img
              src={people}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='الزبائن' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            borderTop: '1px solid gray',
            textAlign: 'right',
            ...getButtonStyle('products'),
          }}
          onClick={() => {
            openPage('products');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ProductionQuantityLimitsIcon /> */}
            <img
              src={product}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='المنتجات' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('orders'),
          }}
          onClick={() => {
            openPage('orders');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ViewStreamIcon /> */}
            <img
              src={order}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='الطلبيات ' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('addorder'),
          }}
          onClick={() => {
            openPage('addorder');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            <img
              src={scanner}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='نقطة البيع' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('cities'),
          }}
          onClick={() => {
            openPage('cities');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <LocationCityIcon /> */}
            <img
              src={cityscape}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='المدن' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}

        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('coupons'),
          }}
          onClick={() => {
            openPage('coupons');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ConfirmationNumberIcon /> */}
            <img
              src={coupon}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='الكوبونات' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('suppliers'),
          }}
          onClick={() => {
            openPage('suppliers');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ConfirmationNumberIcon /> */}
            <img
              src={supplier}
              alt='Selected'
              style={{ width: '30px', height: '30px' }}
            />
          </ListItemIcon>
          <ListItemText primary='الموردين' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('purchases'),
          }}
          onClick={() => {
            openPage('purchases');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ConfirmationNumberIcon /> */}
            {/* <ConfirmationNumberIcon /> */}
            <img
              src={purchase}
              alt='Selected'
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary='المشتريات' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('expenses'),
          }}
          onClick={() => {
            openPage('expenses');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            {/* <ConfirmationNumberIcon /> */}
            {/* <ConfirmationNumberIcon /> */}
            <img
              src={expenses}
              alt='Selected'
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary='المصروفات' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('banners'),
          }}
          onClick={() => {
            openPage('banners');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            <img
              src={banner}
              alt='Selected'
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary='الاعلانات' />
        </ListItemButton>
        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{
            textAlign: 'right',
            borderTop: '1px solid gray',
            ...getButtonStyle('ChangePassword'),
          }}
          onClick={() => {
            openPage('ChangePassword');
            if (isSmallScreen) toggleSidebar();
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px' }}>
            <img
              src={password}
              alt='Selected'
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary='تغيير كلمة المرور' />
        </ListItemButton>

        {/* <hr style={{ color: '#ffffffff' }} /> */}
        <ListItemButton
          sx={{ textAlign: 'right', borderTop: '1px solid gray' }}
          onClick={() => {
            openPage('login');
          }}
        >
          <ListItemIcon sx={{ marginRight: '15px', color: 'red' }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='خروج' sx={{ color: 'red' }} />
        </ListItemButton>
      </List>
    </Box>
  );
}
