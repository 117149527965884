import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Loading from './Loading';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { changePassword } from '../APIS';

const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: 'top-left',
    });
  };

  const notifyError = (msg) => {
    toast.error(msg, {
      position: 'top-left',
    });
  };

  async function handleChangePasswordButton() {
    setLoading(true);
    try {
      if (oldPassword != '' && newPassword != '' && confirmNewPassword != '') {
        if (newPassword === confirmNewPassword) {
          const result = await changePassword(oldPassword, newPassword);
          if (result.status === 'success') {
            notifySuccess(result.msg);
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
          }

          if (result.status === 'err') {
            notifyError(result.msg);
          }
        } else {
          notifyError('الرجاء التأكد من التطابق');
        }
      } else {
        notifyError('الرجاء تعبئة كافة الحقول');
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {});

  return (
    <>
      {loading ? <Loading /> : null}
      <Box style={styles.container}>
        <Box style={styles.header}>
          <h3>تغيير كلمة المرور</h3>
        </Box>
        <Box>
          <h3>كلمة المرور القديمة</h3>
          <Input.Password
            style={styles.inputStyle}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <h3>كلمة المرور الجديدة</h3>
          <Input.Password
            style={styles.inputStyle}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <h3>تأكيد كلمة المرور الجديدة</h3>
          <Input.Password
            style={styles.inputStyle}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            placeholder='كلمة المرور'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />

          <Button
            type='primary'
            style={styles.buttonStyle}
            onClick={handleChangePasswordButton}
          >
            تغيير
          </Button>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px',
    width: '70%',
  },
  inputStyle: {
    margin: '5px',
  },

  buttonStyle: {
    backgroundColor: '#3C7BBE',
    color: 'white',
    margin: '30px',
  },
};

export default ChangePassword;
